<template>
  <div class="container">
    <div v-for="(box, index) in menuItems" class="row box justify-content-center" :key="index">
      <div class="col-12">
        <div class="box-back">
          <div class="box-content">
            <h2 class="text-center mb-3">{{ box.title }}</h2>
            <h4 v-if=box.subtitle class="text-center mb-3">{{ box.subtitle }}</h4>
            <menu-item v-for="(item, index) in box.items"
                       :title="item.name"
                       :subtitle="item.ingredients"
                       :price="item.price"
                       :key="index">
            </menu-item>
          </div>
        </div>
      </div>
    </div>
    <div class="text-bold text-center my-3">Coperto / Gedeck / Cover Charge €2.50</div>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem";
import {menu} from '../assets/menu.json'

export default {
  name: "Menu",
  data() {
    return {
      menuItems: menu
    }
  },
  components: {
    MenuItem
  }
}
</script>

<style scoped>
h2 {
  color: #4a558d;
}

.box {
  margin: 35px 0;
}

.box-back {
  border: 1px solid #4a558d;
  -webkit-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  transform: rotate(-2deg);
  position: relative;
  background-color: #4a558d;
}

.box-content {
  border: 1px solid #202645;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
}

@media screen and (max-width: 650px) {
  .box-back {
    -webkit-transform: rotate(-0.7deg);
    -o-transform: rotate(-0.7deg);
    transform: rotate(-0.7deg);
  }

  .box-content {
    -webkit-transform: rotate(0.7deg);
    -o-transform: rotate(0.7deg);
    transform: rotate(0.7deg);
  }
}

.text-bold {
  font-weight: bold;
}
</style>
