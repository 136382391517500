<template>
  <div class="position-fixed w-100 bg-white text-center" style="z-index: 5">
    <img src="/image/logo.png" class="p-2 w-100" style="max-width: 200px">
  </div>
  <div class="cover text-center">
    <h1>Menù</h1>
  </div>
  <Menu class="pb-5"></Menu>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
#app {
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  background-color: #f1f1f1;
}

.cover {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/image/cover.jpg') center;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
