<template>
  <div class="row">
    <div class="col-10 col-md-8">
      <p class="title" :class="subtitle ? 'm-0' : ''">{{ title }}</p>
    </div>
    <div class="col-2 col-md-4 d-flex justify-content-center" v-if="price">
      {{ price.toFixed(2) }}
    </div>
  </div>
  <div class="row">
    <div v-if="subtitle" class="col-12 mb-3">
      <small v-for="(sub, index) in subtitle" :key="index"><i>{{ sub }}</i><br></small>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    title: String,
    subtitle: String,
    price: Number
  }
}
</script>

<style scoped>
.title {
  font-size: 1.1em;
  font-weight: bold;
}

small {
  color: #2d2d2d;
}

@media screen and (max-width: 650px) {
  .title {
    font-size: 1em
  }
  small {
    font-size: 0.75em;
  }
}
</style>